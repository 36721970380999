.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 48px 66px 80px;
  width: 100%;
  height: 100%;

  @media (max-width: 1024px) {
    padding: 8px 16px 48px;
  }
}

.h1text {
  font-size: 40px;
  font-weight: 900;
  text-align: center;

  @media (max-width: 1024px) {
    font-size: 28px;
  }
}

.h2text {
  color: #626873;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  margin-top: 1.2rem;

  @media (max-width: 1024px) {
    font-size: 18px;
  }
}

.smaller {
  display: block;
  color: #b5b5b5;
  font-size: 14px;
  font-weight: 600;
  margin-top: 0.8rem;
}
