.note {
  align-items: center;
  background-color: #fff;
  border: none;
  border-radius: 50%;
  box-shadow: 0px 3.5px 17px rgba(115, 137, 170, 0.3);
  color: #636c7b;
  cursor: pointer;
  display: flex;
  font-size: 17px;
  font-weight: 600;
  justify-content: center;
  outline: none;
  // transition: .15s ease-in-out;
  width: 48px;
  height: 48px;


  @media (hover: hover) {
    &:hover,
    &:active {
      background-color: #5f93f8;
      color: #fff;
    }
  }
  
}

.noteAutoPlay {
  composes: note;
  background-color: #5f93f8 !important;
  color: #fff;
}
