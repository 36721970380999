.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  margin-top: 40px;

  @media (max-width: 1024px) {
    margin-top: 0px;
  }
}

.instrument {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 12px;
  @media (max-width: 1024px) {
    margin-top: 48px;
  }
  &:hover {
    background-color: rgb(0, 0, 0, .05);
    border-radius: 12px;
  }
}

.instrumentImage {
  height: 236px;
  width: 200px;
}

.instrumentName {
  color: #444;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  margin-top: 22px;

  & * {
    text-decoration: none;
  }
}

.instrumentButton {
  align-items: center;
  background-color: #5f93f8;
  border-radius: 6px;
  box-shadow: 0px 2px 4px #cccccc;
  color: #fff;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  flex-direction: row;
  justify-content: center;
  height: 38px;
  padding: 14px;
  margin-top: 1rem;
}
