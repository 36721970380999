.container {
  display: flex;
  flex-direction: row;

  @media (max-width: 1024px) {
    align-self: center;
    flex-direction: column;
  }
}

.instrumentContainer {
  align-items: flex-end;
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-grow: 1;

  @media (max-width: 1024px) {
    align-self: center;
    align-items: center;
    flex-direction: column;
    // padding-top: 60px;
  }

  h1 {
    color: #75787A;
    font-weight: 600;
    position: absolute;
    font-size: 18px;
    top: 2rem;
    left: 3.5rem;

    @media (max-width: 1024px) {
      // display: block;
      position: unset;
      padding: 0 1.5rem;
      text-align: center;
    }
  }

  .indicatorContainer {
    display: none;
    position: relative;
    margin-top: 24px;
    padding-bottom: 22px;
    margin-bottom: 10px;

    @media (max-width: 1024px) {
      display: block;
    }
  }
  
}

.tuningSectionContainer {
  flex-grow: 1;
  position: relative;
}

