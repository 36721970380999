.container {
  margin-top: 32px;
  width: 100%;

  * {
    color: #fff;
  }
}

.banner {
  background: linear-gradient(300deg, #56d3e4 20%, #5f93f8 75%);
  border-radius: 28px;
  box-shadow: 0px 0px 0px 10px #c0d3f8;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100px;
  padding: 50px 140px 90px;
  width: 100%;

  @media (max-width: 1024px) {
    box-shadow: 0px 0px 0px 4px #c0d3f8;
    width: auto;
    margin-left: 5px;
    margin-right: 5px;
    padding: 2rem 0 0;

    flex-direction: column;
  }

  .tunedUpText {
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
    text-align: center;

    @media (max-width: 1024px) {
      font-size: 18px;
      padding: 0 32px;
    }
  }

  .playerContainer {
    width: 400px;
    height: 400px;
    margin-top: -160px !important;
    margin-bottom: -110px;
    margin-left: -200px;

    :global(#lottie) {
      width: 400px;
      height: 400px;
    }

    @media (max-width: 1024px) {
      margin-left: -40px;
      margin-right: -40px;
      margin-top: -120px !important;
      margin-bottom: -20px;
      width: 250px;
      height: 250px;

      :global(#lottie) {
        width: 250px;
        height: 250px;
      }
    }
  }

  .grid {
    align-items: center;
    display: flex;
    column-gap: 0px;
    flex-direction: row;
    justify-content: center;
    position: relative;
    margin-top: 24px;
    margin-left: -64px;
    padding-top: 24px;

    @media (max-width: 1024px) {
      flex-direction: column;
      margin-left: 0;
    }

    .buttonContainer {
      height: 43px;
      position: relative;
    }

    .rippleEffect {
      box-sizing: content-box;
      animation: ripple 0.6s linear infinite;
      box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 1);
      border-radius: 30px;
      z-index: 1;
    }

    @keyframes ripple {
      0% {
        box-shadow: 0 4px 10px rgba(255, 255, 255, 0.2), 0 0 0 0 rgba(255, 255, 255, 0.2),
          0 0 0 5px rgba(255, 255, 255, 0.2), 0 0 0 10px rgba(255, 255, 255, 0.2);
      }
      100% {
        box-shadow: 0 4px 10px rgba(255, 255, 255, 0.2), 0 0 0 5px rgba(255, 255, 255, 0.2),
          0 0 0 10px rgba(255, 255, 255, 0.2), 0 0 0 20px rgba(255, 255, 255, 0);
      }
    }

    .button {
      align-items: center;
      border-radius: 30px;
      border: none;
      box-sizing: border-box;
      color: #fff;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      font-size: 18px;
      font-weight: 900;
      outline: none;
      padding: 12px 25px;
      text-align: center;
      white-space: nowrap;
      height: 43px;
      z-index: 100;
    }

    .buttonPrimary {

      align-items: center;
      background-color: #fff;
      border-radius: 30px;
      border: none;
      box-sizing: border-box;
      color: rgba(115, 205, 237, 1);
      cursor: pointer;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      font-size: 18px;
      font-weight: 900;
      outline: none;
      padding: 12px 25px;
      text-align: center;
      white-space: nowrap;
      height: 43px;
      z-index: 100;
    }

    .marketingBlock {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      min-width: 385px;
      width: 420px;

      table {
        border-spacing: 20px;
        margin-left: -50px;

        @media (max-width: 1024px) {
          margin-left: 0px;
        }
        
        td {
          white-space: nowrap
        }

        td:nth-of-type(1n) {
          min-width: 82px;
        }

        .priceColumn {
          @media (max-width: 580px) {
            display: none;
          }
        }

        .ratingColumn {
          @media (max-width: 430px) {
            display: none;
          }
        }
      }

      @media (max-width: 1024px) {
        width: auto;
        min-width: unset;
        align-items: center;
        margin-bottom: 32px;
      }
    }
  }

  .text {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5;

    strong {
      color: #fff;
      font-weight: 700;
    }

    @media (max-width: 1024px) {
      text-align: center;
    }
  }
}
