.switchWrapper {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;

  span {
    color: #75787a;
    font-size: 14px;
    font-weight: 500;
    margin-left: 11px;
  }

  input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: none;
  }
}

.switchWrapperActive {
  composes: switchWrapper;

  .switchBody {
    background-color: #5f93f8;
  }

  .switchCircle {
    transform: translateX(18px);
  }
}

.switchDisabled {
  cursor: default;

  opacity: 0.3;
}

.switchBody {
  align-items: center;
  background-color: #cdcdcd;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  height: 25px;
  width: 45px;
}

.switchCircle {
  background-color: #fff;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  margin-left: 5px;
  margin-right: 5px;
  transition: 0.2s ease-in-out;

  transform: translateX(0);
}