.container {
  position: relative;
  margin: 0 auto 35px;
  display: block;
  width: 100%;
}

.card {
  align-items: flex-start;
  background-color: #fefefe;
  border-radius: 28px;
  box-shadow: 0px 0px 0px 10px #ecf2f8;
  height: 100%;
  min-height: 100px;
  width: 100%;
  z-index: 100;

  @media (max-width: 1024px) {
    box-shadow: 0px 0px 0px 4px #ecf2f8;
    width: calc(100% - 8px);
    margin-left: 4px;
    margin-right: 4px;
    padding: 2rem 0 0;

    flex-direction: column;
  }
}