.container {
  margin-top: 32px;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 540px) {
    height: 0px;
    visibility: collapse;
  }
}

.banner {
  align-items: center;
  width: 540px;
  height: 283px;
  border-radius: 12px;
  // background: url("/so/FreeAcousticGuitarLessons_Feb20221_40.jpeg"); // GUITAREO
  background: url("/so/CC_1080x566_guitar.jpg");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: right;
}

.soLogo {
  margin-bottom: 48px;
}

.textInstantHits {
  margin-bottom: 52px;
}

.ctaButton {
  height: 81px;
  margin-bottom: 54px;
}

.coolButton {
  // background-color: #1abebe; // GUITAREO
  background: linear-gradient(121.19deg, #3d4ff6, #5F93F8);
  color: white;
  padding: 10px 32px;
  margin-right: 14px;
  font-size: 16px;
  border-radius: 200px;
  box-shadow: 0 0 0.75rem rgb(3, 3, 3);
  font-weight: 800;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  will-change: transform;
  mask-image: -webkit-radial-gradient(white, black);

  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 600px;
    left: 50%;
    margin-left: -300px;
    background: linear-gradient(
      121.19deg,
      rgba(255, 255, 42559, 0) 40%,
      rgba(255, 255, 255, 0.3) 50%,
      rgba(255, 255, 255, 0) 60%
    );
    animation: btn-animation 30s infinite linear;
  }
}

@keyframes btn-animation {
  0% {
    background-position: -4000px 0;
  }

  to {
    background-position: 8000px 0;
  }
}
