.container {
  background-color: #010101;
  border-bottom: 1px solid #eaeaea;
  height: 75px;
  position: sticky;
  top: 0;
  z-index: 200;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.content {
  // background: url("/so/FretboardCheatSheet_728x90_edited.jpeg"); // GUITAREO
  background: url("/so/CC_728x90_guitar.jpg");
  @media (max-width: 550px) {
    background: url("/so/CC_728x90_mini_guitar.jpg");
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
  }
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  @media (max-width: 610px) {
    background-position: left;
  }
  display: flex;
  justify-content: right;
  align-items: center;
  // box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // text-align: center;
  // color: #fff;
  // font-size: 22px;

  // * {
  //     display: none;
  // }

  // @media (max-width: 1024px) {
  //     background: #007AF7;
  //     width: 100%;
  //     justify-content: space-between;
  //     padding: 0 9px;

  //     @media (max-width: 374px) {
  //         .pack {
  //             display: none;
  //             padding: none;
  //         }
  //     }

  //     .pack {
  //         padding-left: 6px;
  //     }

  //     * {
  //         display: block;
  //     }
  // }
}

.coolButton {
  @media (min-width: 611px) {
    position: absolute;
    left: calc(50% + 100px);
  }
  // background-color: #1abe9a; GUITAREO
  background: linear-gradient(121.19deg, #3d4ff6, #5F93F8);
  color: white;
  overflow: hidden;
  padding: 10px 32px;
  margin-right: 8px;
  font-size: 16px;
  @media screen and (max-width: 540px) {
    font-size: 14px;
    padding: 10px 12px;
  }
  margin-bottom: 1px;
  border-radius: 200px;
  box-shadow: 0 0 0.75rem rgb(3, 3, 3);
  font-weight: 800;
  cursor: pointer;
  text-align: center;
  will-change: transform;
  mask-image: -webkit-radial-gradient(white, black);
  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 600px;
    left: 50%;
    margin-left: -300px;
    background: linear-gradient(
      121.19deg,
      rgba(255, 255, 42559, 0) 40%,
      rgba(255, 255, 255, 0.3) 50%,
      rgba(255, 255, 255, 0) 60%
    );
    animation: btn-animation 30s infinite linear;
  }
}

@keyframes btn-animation {
  0% {
    background-position: -6000px 0;
  }

  to {
    background-position: 6000px 0;
  }
}
