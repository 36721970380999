.heading {
  color: #333;
  font-size: 54px;
  margin: 58px auto;
  text-align: center;

  @media (max-width: 1024px) {
    font-size: 44px;
  }
}

.textContainer {
  padding: 4rem 7.8rem 6rem;

  @media (max-width: 1024px) {
    padding: 0rem 2rem 2rem;
  }

  p, ol, ul {
    color: #333;
    font-size: 18px;
    font-family: "Hind", sans-serif;
    line-height: 1.6;
  }
}
