.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;

  opacity: 0;
  transition: 0.15s ease-in-out;
  top: 50px;

  @media (max-width: 1024px) {
    margin-bottom: 20px;
  }
}

.pointerContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 100;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.2s ease-in-out;
  width: 70px;
  height: 84px;

  @media (max-width: 1024px) {
    width: 48px;
    height: 60px;
  }

  .pointer {
    fill: #6b92f1;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .pointerCorrect {
    fill: #29d7b7;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  span {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    position: absolute;
    transform: translateY(-5px);
    z-index: 10;

    @media (max-width: 1024px) {
      font-size: 16px;
    }
  }

  .pointerBall {
    background-color: #6b92f1;
    bottom: -24px;
    border-radius: 50%;
    position: absolute;
    width: 16px;
    height: 16px;
  }

  .pointerBallCorrect {
    background-color: #29d7b7;
    bottom: -24px;
    border-radius: 50%;
    position: absolute;
    width: 16px;
    height: 16px;
  }
}

.bar {
  background: #fff;
  border: 1px solid #E6E6E6;
  border-radius: 45px;
  width: 270px;
  height: 20px;
  position: relative;
}

.inTuneComment {
  color: #444;
  font-weight: 700;
  position: absolute;
  top: -50px;
  left: 50%;
}

.low {
  composes: inTuneComment;

  transform: translateX(65px);
}

.high {
  composes: inTuneComment;

  transform: translateX(-120px);
}

.middle {
  background: #6b92f190;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.middleCorrect {
  composes: middle;

  background: #29d7b780;
}

.tick {
  height: 15px;
  z-index: 10;

  @media (max-width: 1024px) {
    height: 12px;
    transform: translateY(-3px);
  }
}

.visible {
  opacity: 1;
}
