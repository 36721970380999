.container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  height: 100%;
}

.indicatorContainer {
  display: block;
  position: relative;
  margin-bottom: 120px;

  @media screen and (max-width: 1024px) {
    display: none;
    margin-top: 16px;
    padding-bottom: 30px;
    margin-bottom: 10px;
  }
}

.controlPanel {
  border: 1px solid #E6E6E6;
  border-bottom: none;
  border-radius: 28px 28px 0 0;
  padding: 34px 28px 0;
  width: 270px;

  @media screen and (max-width: 1024px) {
    background-color: #F3F8FD;
    border: none;
    border-top: 1px solid #e6e6e6;
    border-radius: 0 0 28px 28px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.button {
  align-items: center;
  background-color: #5f93f8;
  border: none;
  border-radius: 12px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 13px;
  font-weight: 700;
  transition: 0.15s ease-in-out;
  outline: none;
  padding: 6px 24px;
  text-transform: uppercase;

  &:hover {
    background-color: #5f93f8dd;
  }

  &:active {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  }

  .microphone {
    height: 25px;
    width: 20px;
    margin-right: 8px;
  }
}