.container {
  align-items: center;
  margin-bottom: 24px;

  @media (max-width: 1024px) {
    align-self: center;
  }
}

.switchBody {
  position: relative;
}

.switchOptions {
  background-color: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  .switchOption {
    align-items: center;
    color: #b5b5b5;
    cursor: pointer;
    display: flex;
    font-size: 13px;
    font-weight: 700;
    flex-direction: row;
    justify-content: center;
    transition: 0.2s ease-in-out;
    padding: 10px 16px;
    user-select: none;
    z-index: 100;
    white-space: nowrap;

    &,
    & * {
      fill: #b5b5b5;
    }

    &:first-of-type {
      margin-right: 5px;
    }
  }

  .activeOption {
    color: #fff;

    &,
    & * {
      fill: #fff;
    }
  }
}

.headphoneImg {
  margin-right: 8px;
}

.microphoneImg {
  margin-right: 8px;
}

.switchCircle {
  background-color: #5f93f8;
  border-radius: 50px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  transition: 0.15s ease-in-out;
  width: 60%;
  z-index: 10;
}

.microphoneSelected {
  .switchCircle {
    width: 45%;
    transform: translateX(123%);
  }
}
