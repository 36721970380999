.message {
  color: #626873;
  font-size: 13px;
  font-weight: 700;
  max-width: 260px;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;

  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5.8rem;
}

.instrumentContainer {
  position: relative;
  margin-top: 50px;
  min-width: 330px;

  /* *********************** */

  @media (max-width: 1024px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    /* Hack to re-create overflow-y:hidden and overflow-x: visible */
    padding-top: 40px;
    margin-top: -40px;
    /* *********************** */
  }

  &:global(.string-0) :global(#key-0),
  &:global(.string-0) :global(#neck-0),
  &:global(.string-0) :global(#string-0) {
    fill: #5f93f8;
    stroke: #5f93f8;
  }

  &:global(.string-1) :global(#key-1),
  &:global(.string-1) :global(#neck-1),
  &:global(.string-1) :global(#string-1) {
    fill: #5f93f8;
    stroke: #5f93f8;
  }

  &:global(.string-2) :global(#key-2),
  &:global(.string-2) :global(#neck-2),
  &:global(.string-2) :global(#string-2) {
    fill: #5f93f8;
    stroke: #5f93f8;
  }

  &:global(.string-3) :global(#key-3),
  &:global(.string-3) :global(#neck-3),
  &:global(.string-3) :global(#string-3) {
    fill: #5f93f8;
    stroke: #5f93f8;
  }

  &:global(.string-4) :global(#key-4),
  &:global(.string-4) :global(#neck-4),
  &:global(.string-4) :global(#string-4) {
    fill: #5f93f8;
    stroke: #5f93f8;
  }

  &:global(.string-5) :global(#key-5),
  &:global(.string-5) :global(#neck-5),
  &:global(.string-5) :global(#string-5) {
    fill: #5f93f8;
    stroke: #5f93f8;
  }
}

.instrumentWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  animation: slide-in 0.2s ease-in-out;

  @keyframes slide-in {
    0% {
      transform: translateY(90%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

.instrument {
  height: 600px;

  @media (max-width: 1024px) {
    height: 350px;
    width: auto;
  }
}

.notes {
  position: absolute;

  
  :global(.string-0) & > :nth-child(1),
  :global(.string-0-manual) & > :nth-child(1) {
    background-color: #5f93f8;
    color: #fff;
  }
  :global(.string-tuned-0) & > :nth-child(1) {
    background-color: #29d7b7;
    color: #fff;
  }
  

  :global(.string-1) & > :nth-child(2),
  :global(.string-1-manual) & > :nth-child(2) {
    background-color: #5f93f8;
    color: #fff;
  }
  :global(.string-tuned-1) & > :nth-child(2) {
    background-color: #29d7b7;
    color: #fff;
  }

  :global(.string-2) & > :nth-child(3),
  :global(.string-2-manual) & > :nth-child(3) {
    background-color: #5f93f8;
    color: #fff;
  }
  :global(.string-tuned-2) & > :nth-child(3) {
    background-color: #29d7b7;
    color: #fff;
  }

  :global(.string-3) & > :nth-child(4),
  :global(.string-3-manual) & > :nth-child(4) {
    background-color: #5f93f8;
    color: #fff;
  }
  :global(.string-tuned-3) & > :nth-child(4) {
    background-color: #29d7b7;
    color: #fff;
  }

  :global(.string-4) & > :nth-child(5),
  :global(.string-4-manual) & > :nth-child(5) {
    background-color: #5f93f8;
    color: #fff;
  }
  :global(.string-tuned-4) & > :nth-child(5) {
    background-color: #29d7b7;
    color: #fff;
  }

  :global(.string-5) & > :nth-child(6),
  :global(.string-5-manual) & > :nth-child(6) {
    background-color: #5f93f8;
    color: #fff;
  }
  :global(.string-tuned-5) & > :nth-child(6) {
    background-color: #29d7b7;
    color: #fff;
  }
}

.notes-acoustic-guitar {
  composes: notes;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 320px;
  grid-row-gap: 30px;
  bottom: 250px;
  left: 15px;

  @media (max-width: 1024px) {
    top: 50px;
    left: -20px;
    grid-column-gap: 195px;
  }

  & > :nth-child(1) {
    order: 4;
  }

  & > :nth-child(2) {
    order: 2;
  }

  & > :nth-child(3) {
    order: 0;
  }

  & > :nth-child(4) {
    order: 1;
  }

  & > :nth-child(5) {
    order: 3;
  }

  & > :nth-child(6) {
    order: 5;
  }
}

.notes-electric-guitar {
  composes: notes;
  display: flex;
  flex-direction: column-reverse;
  bottom: 210px;
  left: 25px;

  @media (max-width: 1024px) {
    bottom: 80px;
    left: -10px;
    transform: scale(0.8);
  }

  & > * {
    position: absolute;
  }

  & > :nth-child(1) {
    left: 0px;
    bottom: 0;
  }

  & > :nth-child(2) {
    left: 18px;
    bottom: 55px;
  }

  & > :nth-child(3) {
    left: 36px;
    bottom: 110px;
  }

  & > :nth-child(4) {
    left: 54px;
    bottom: 165px;
  }

  & > :nth-child(5) {
    left: 72px;
    bottom: 220px;
  }

  & > :nth-child(6) {
    left: 90px;
    bottom: 275px;
  }
}

.notes-bass-guitar {
  composes: notes;
  display: flex;
  flex-direction: column-reverse;
  bottom: 210px;
  left: 0px;

  & > * {
    position: absolute;
  }

  & > :nth-child(1) {
    left: 0px;
    bottom: 5px;
  }

  & > :nth-child(2) {
    left: 15px;
    bottom: 95px;
  }

  & > :nth-child(3) {
    left: 35px;
    bottom: 185px;
  }

  & > :nth-child(4) {
    left: 55px;
    bottom: 275px;
  }

  @media (max-width: 1024px) {
    bottom: 75px;
    left: -25px;
    grid-column-gap: 195px;
    transform: scale(0.95);

    & > :nth-child(1) {
      left: 5px;
      bottom: 30px;
    }

    & > :nth-child(2) {
      left: 17px;
      bottom: 95px;
    }

    & > :nth-child(3) {
      left: 29px;
      bottom: 160px;
    }

    & > :nth-child(4) {
      left: 41px;
      bottom: 225px;
    }
  }
}

.notes-ukulele {
  composes: notes;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 320px;
  grid-row-gap: 80px;
  bottom: 255px;
  left: 20px;

  @media (max-width: 1024px) {
    bottom: 125px;
    left: -15px;
    grid-row-gap: 55px;
    grid-column-gap: 200px;
  }

  & > :nth-child(1) {
    order: 2;
  }

  & > :nth-child(2) {
    order: 0;
  }

  & > :nth-child(3) {
    order: 1;
  }

  & > :nth-child(4) {
    order: 3;
  }
}
