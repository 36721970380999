.container {
  padding-bottom: 12px;
  position: relative;
}

.heading {
  color: #626873;
  display: block;
  font-size: 13px;
  font-weight: 700;
  line-height: 1.6;
  text-align: left;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 24px;
}

.tuningsList {
  list-style: none;
  overflow-y: hidden; // TODO: change to scroll
  height: 105px;
  min-width: 200px;

  .option {
    cursor: default; // TODO: change to pointer
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 16px;
    opacity: 0.25;
  }
}

:global(.selected).option {
  cursor: default;
  opacity: 1;
}

:global(.selected) {
  .optionName {
    color: #5f93f8;
    font-weight: 600;
  }
}

.optionName {
  color: #b5b5b5;
  font-size: 13px;
  font-weight: 500;
  padding-right: 30px;
}

.optionNotes {
  composes: optionName;
  letter-spacing: -0.1em;
  padding-right: 2px;
}

.comingSoon {
  color: #75787a;
  font-size: 13px;
  font-weight: 700;
  position: absolute;
  top: 95px;
  left: 50%;
  width: 100%;
  text-align: center;
  transform: translateX(-50%);
  text-transform: uppercase;
}
