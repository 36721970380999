.container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.15s ease-in-out;
  top: 0;
  width: 100%;
  
  opacity: 0;
}

.dimmedHeading {
  color: #b5b5b5;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 14px;
}

.mainInstruction {
  color: #626873;
  font-size: 13px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.highlighter {
  background-color: #5f93f8;
  border-radius: 4px;
  width: 100px;
  height: 4px;
  margin: 0 auto;
}

.invisible {
  opacity: 0;
}

.visible {
  opacity: 1;
}
